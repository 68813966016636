.media-theme-docflix * {
    font-size: 13px;
    font-family: Sora, Roboto, Arial, sans-serif;
    color: #fff;
    --media-range-track-height: 4px;
    --media-range-thumb-height: 13px;
    --media-range-thumb-width: 13px;
    --media-range-thumb-border-radius: 13px;
    --media-icon-color: #fff;
    background: none;
  }
  .media-theme-docflix {
    /* width: 1080px; */
    /* height: calc(100vh * (9 / 16)); */
    transform: translate(-50%, -50%);
    position: absolute;
    height: 80%;
    width: 80%;
    z-index: 9;
    display: block
  }
  
  .mobile_Player {
    display: flex !important;
  }
  .showplayer {
    z-index: 1;
  }
  .hideplayer {
    z-index: 0;
  }
  
  .fullScreenContainer {
    min-width: 3rem;
    display: flex;
    flex-direction: column;
  }
  .fullScreenContainerMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .note-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: 1px solid #fff;
    border-radius: 0.2rem;
    margin: 0.3rem 0;
    cursor: pointer;
  }
  .media-theme-docflix *:hover {
    color: #fff;
    --media-icon-color: #fff;
  }
  .media-theme-docflix media-control-bar {
    position: relative;
  }
  .media-theme-docflix media-control-bar > *:hover {
    background: transparent;
  }
  
  .media-theme-docflix media-play-button {
    --media-button-icon-width: 30px;
    /* --media-icon-color: #eb4547; */
  }
  .seekOverlay {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
  }
  
  .media-theme-docflix media-time-range {
    height: auto;
  
    padding-right: 0;
  
    --media-range-track-transition: height 0.1s linear;
    --media-range-track-background: #444;
  
    --media-range-bar-color: #eb4547;
    --media-progress-buffered-color: #999;
  
    --media-range-thumb-border-radius: 13px;
    --media-range-thumb-background: #eb4547;
  
    --media-range-thumb-transition: transform 0.1s linear;
    --media-range-thumb-transform: scale(0) translate(0%, 0%);
  }
  
  .media-theme-docflix media-time-range:hover {
    --media-range-track-height: 5px;
    --media-range-thumb-transform: scale(1) translate(0%, 0%);
  }
  
  .media-theme-docflix media-volume-range {
    padding-left: 0px;
    height: 100%;
    --media-range-track-background: #fff;
    --media-range-bar-color: #eb4547;
    --media-range-thumb-background: #eb4547;
  }
  
  .media-theme-docflix .control-spacer {
    flex-grow: 1;
  }
  
  .media-theme-docflix media-mute-button + media-volume-range {
    width: 0px;
    overflow: hidden;
    padding-right: 0px;
  
    /* Set the internal width so it reveals, not grows */
    --media-range-track-width: 60px;
    transition: width 0.3s ease-in;
  }
  
  /* Expand volume control in all relevant states */
  .media-theme-docflix media-mute-button:hover + media-volume-range,
  .media-theme-docflix media-mute-button:focus + media-volume-range,
  .media-theme-docflix media-mute-button:focus-within + media-volume-range,
  .media-theme-docflix media-volume-range:hover,
  .media-theme-docflix media-volume-range:focus,
  .media-theme-docflix media-volume-range:focus-within {
    width: 70px;
    align-self: center;
  }
  
  // event container
  .event-container {
    padding: 0 80px;
  }
  
  /* event play button */
  .event-play-btn {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }
  .backbtn {
    position: absolute;
    top: 3%;
    left: 2%;
    cursor: pointer;
  }
  
  @media screen and (max-width: 768px) and (orientation: portrait) {
    .media-theme-docflix media-time-range {
      padding-right: 10px;
    }
  }
  
  @media screen and (max-width: 430px) and (orientation: portrait) {
    .media-theme-docflix media-play-button,
    .media-theme-docflix media-mute-button,
    .media-theme-docflix media-fullscreen-button {
      padding: 5px;
    }
  }
  