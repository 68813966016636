.modalWrapper {
    position: fixed;
    inset: 0;
    display: grid;
    place-content: center;
}

.modalOverlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.60);
    backdrop-filter: blur(3px);
    z-index: 10;
}

.modal {
    padding: 1.5rem 2rem;
    z-index: 15;
    background-color: #fff;
}

.closeBtn {
    position: fixed;
    top: 5%;
    cursor: pointer;
    right: 10%;
    font-size: 1.4rem;
    color: #fff;
}