.App {
  min-height: 100vh;
  width: 100%;
  background-color: #2c4067;
  margin: 0;
  padding: 2rem;

}

* {
  box-sizing: border-box;
}

.App .header {
  display: flex;
  padding: 1rem 4rem;
  justify-content: space-between;
  background: #fff;
  margin: -2rem;
  margin-bottom: 0;
}

.App .header>img {
  width: 6rem;
  object-fit: contain;
}

.headerLogo>img {
  width: 6rem;
  object-fit: contain;
}

.headerLogo {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #000;
  font-size: 1.2rem;
  font-weight: 500;
}


.videosWrapper {
  display: flex;
  width: 100%;
  padding: 0 2rem;
  margin-top: 4rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.video {
  width: 30rem;
  border-radius: 6px;
  margin: 1.5rem 1rem;
  background: transparent;
  cursor: pointer;
}

.videoUrl {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  object-fit: cover;
  -webkit-box-shadow: 10px 10px 29px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 29px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 29px -6px rgba(0, 0, 0, 0.75);
}

.videoUrl:hover {
  transform: scale(1.05);
  transition: transform 250ms ease;
}


@media screen and (max-width: 768px) and (orientation: portrait) {
  .App .header {
    padding: 1rem;
  }

  .App .header>img {
    width: 5rem;

    object-fit: contain;
  }

  .headerLogo>img {
    width: 5rem;
    object-fit: contain;
  }

  .headerLogo {

    font-size: 0.8rem;
    font-weight: 500;
  }

  .videosWrapper {
    margin-top: 2rem;
  }
}